import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';
import graphData from './graphData.json';

const Project3 = () => {
    // State management
    const [sliderValue, setSliderValue] = useState(1);
    const [isPlaying, setIsPlaying] = useState(false);
    const [speedIndex, setSpeedIndex] = useState(0);
    const [highlightedNode, setHighlightedNode] = useState(null);
    const [originalGraphData, setOriginalGraphData] = useState(null);
    
    // Refs
    const svgRef = useRef(null);
    const svgGRef = useRef(null);
    const nodePositionsSimulation = useRef({});
    const intervalRef = useRef(null);
    
    // Constants
    const speeds = [1, 2, 4, 8];
    const maxSlider = 1000;
    const width = window.innerWidth * 0.5;
    const height = window.innerHeight * 0.7;

    // Initialize data
    useEffect(() => {
        if (graphData) {
            setOriginalGraphData(JSON.parse(JSON.stringify(graphData)));
        }
    }, [graphData]);

    // Initialize the SVG reference
    useEffect(() => {
        if (svgRef.current && !svgGRef.current) {
            svgGRef.current = d3.select(svgRef.current)
                .attr('width', width)
                .attr('height', height)
                .append('g');
        }
    }, [width, height]);

    // Simulate node positions
    const simulateNodePositions = useCallback(() => {
        if (!originalGraphData) return;
        
        const decayConstant = 0.00005;
        const boostFactor = 0.75;
        
        // Scale for boost times
        const xScaleBoost = d3.scaleLinear()
            .domain([
                d3.min(originalGraphData.nodes, d => d.sent), 
                d3.max(originalGraphData.nodes, d => d.sent)
            ])
            .range([0, maxSlider]);
        
        // Initialize simulation for each node
        originalGraphData.nodes.forEach(node => {
            const nodeId = node.id;
            nodePositionsSimulation.current[nodeId] = [];
            
            let currentY = 0;
            let velocity = 0;
            let boostHistory = [];
            
            // Simulate y values for each slider step
            for (let sv = 0; sv <= maxSlider; sv++) {
                if (sv < xScaleBoost(node.sent)) {
                    nodePositionsSimulation.current[nodeId][sv] = 0;
                    continue;
                }
                
                // Apply exponential decay using Euler's method
                let decayForce = decayConstant * (height - currentY);
                velocity += decayForce;
                currentY += velocity;
                
                // Ensure node position never goes below 0
                if (currentY > height) {
                    currentY = height;
                }
                
                // Check if any boost times are passed
                if (node.boost && node.boost.length > 0) {
                    node.boost.forEach(boostTime => {
                        const scaledBoostTime = xScaleBoost(boostTime);
                        
                        if (sv >= scaledBoostTime && !boostHistory.includes(scaledBoostTime)) {
                            currentY = currentY * boostFactor;
                            velocity = 0;
                            boostHistory.push(scaledBoostTime);
                        }
                    });
                }
                
                // Store the simulated y position
                nodePositionsSimulation.current[nodeId][sv] = currentY;
            }
        });
    }, [originalGraphData, height, maxSlider]);

    // Initialize graph when data changes
    useEffect(() => {
        if (!originalGraphData || !svgGRef.current) return;
        
        simulateNodePositions();
        drawGraph();
        
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [originalGraphData, simulateNodePositions]);

    // Draw the initial graph
    const drawGraph = useCallback(() => {
        if (!originalGraphData || !svgGRef.current) return;
        
        const svg = svgGRef.current;
        svg.selectAll("*").remove();
        
        // Calculate fixed x positions based on nodes.sent
        const xScale = d3.scaleLinear()
            .domain([
                d3.min(originalGraphData.nodes, d => d.sent), 
                d3.max(originalGraphData.nodes, d => d.sent)
            ])
            .range([50, width - 50]);
        
        // Set initial positions for nodes
        originalGraphData.nodes.forEach(node => {
            node.x = xScale(node.sent);
            node.y = 0;
        });
        
        // Initialize the links
        svg.selectAll("line")
            .data(originalGraphData.links)
            .enter().append("line")
            .attr("class", "link")
            .style("stroke", "#aaa")
            .attr("x1", d => {
                const sourceNode = originalGraphData.nodes.find(node => node.id === d.source);
                return xScale(sourceNode.sent);
            })
            .attr("y1", d => {
                const sourceNode = originalGraphData.nodes.find(node => node.id === d.source);
                return sourceNode.y;
            })
            .attr("x2", d => {
                const targetNode = originalGraphData.nodes.find(node => node.id === d.target);
                return xScale(targetNode.sent);
            })
            .attr("y2", d => {
                const targetNode = originalGraphData.nodes.find(node => node.id === d.target);
                return targetNode.y;
            });
        
        // Initialize the nodes
        svg.selectAll("circle")
            .data(originalGraphData.nodes)
            .enter().append("circle")
            .attr("class", "node")
            .attr("r", 20)
            .style("fill", d => d.color)
            .attr("cx", d => d.x)
            .attr("cy", d => d.y)
            .on("click", (event, d) => handleNodeClick(d));
        
        // Create node labels
        svg.selectAll(".node-label")
            .data(originalGraphData.nodes)
            .enter().append("text")
            .attr("class", "node-label")
            .text(d => d.id)
            .attr("text-anchor", "middle")
            .attr("dy", 0)
            .attr("x", d => d.x)
            .attr("y", d => d.y)
	    .style("fill", "white");
        
        // Initialize edge labels
        svg.selectAll(".edge-label")
            .data(originalGraphData.links)
            .enter().append("text")
            .attr("class", "edge-label")
            .text(d => d.id[0][0])
            .attr("text-anchor", "middle")
            .attr("x", d => {
                const sourceNode = originalGraphData.nodes.find(node => node.id === d.source);
                const targetNode = originalGraphData.nodes.find(node => node.id === d.target);
                return (sourceNode.x + targetNode.x) / 2;
            })
            .attr("y", d => {
                const sourceNode = originalGraphData.nodes.find(node => node.id === d.source);
                const targetNode = originalGraphData.nodes.find(node => node.id === d.target);
                return (sourceNode.y + targetNode.y) / 2;
            })
	    .style("fill", "white");
        
        // Update based on current slider value
        updateGraphYPosition(sliderValue);
        opacityChanges(sliderValue);
        updateEdgeLabels(sliderValue);
        updateNodeSize(sliderValue);
        setOriginalText(sliderValue, highlightedNode);
    }, [originalGraphData, width, sliderValue, highlightedNode]);

    // Update graph when slider changes
    useEffect(() => {
        if (originalGraphData) {
            updateGraphYPosition(sliderValue);
            opacityChanges(sliderValue);
            updateEdgeLabels(sliderValue);
            updateNodeSize(sliderValue);
            setOriginalText(sliderValue, highlightedNode);
        }
    }, [sliderValue, highlightedNode, originalGraphData]);

    // Handle node click
    const handleNodeClick = useCallback((clickedNode) => {
        if (highlightedNode && clickedNode.id === highlightedNode.id) {
            setHighlightedNode(null);
            drawGraph();
        } else {
            setHighlightedNode(clickedNode);
            drawSubsetGraph(clickedNode);
            highlightNode(clickedNode);
        }
    }, [highlightedNode, drawGraph]);

    // Draw subset graph (filtered view)
    const drawSubsetGraph = useCallback((node) => {
        if (!originalGraphData || !svgGRef.current) return;
        
        // Get all related nodes based on the links
        const relatedNodeIds = new Set();
        const relatedLinks = [];

        relatedNodeIds.add(node.id);
        originalGraphData.links.forEach(link => {
            if (link.source === node.id || (link.source.id && link.source.id === node.id)) {
                relatedNodeIds.add(link.target.id || link.target);
                relatedLinks.push({
                    source: link.source.id || link.source,
                    target: link.target.id || link.target,
                    id: link.id
                });
            } else if (link.target === node.id || (link.target.id && link.target.id === node.id)) {
                relatedNodeIds.add(link.source.id || link.source);
                relatedLinks.push({
                    source: link.source.id || link.source,
                    target: link.target.id || link.target,
                    id: link.id
                });
            }
        });

        const filteredNodes = originalGraphData.nodes.filter(n => relatedNodeIds.has(n.id));
        const subsetGraphData = {
            nodes: filteredNodes,
            links: relatedLinks,
            text: originalGraphData.text,
            coreferences: originalGraphData.coreferences
        };
        
        // Update the view with subset data
        const svg = svgGRef.current;
        svg.selectAll("*").remove();
        
        // Calculate fixed x positions
        const xScale = d3.scaleLinear()
            .domain([
                d3.min(subsetGraphData.nodes, d => d.sent), 
                d3.max(subsetGraphData.nodes, d => d.sent)
            ])
            .range([50, width - 50]);
        
        // Set initial positions
        subsetGraphData.nodes.forEach(node => {
            node.x = xScale(node.sent);
            node.y = nodePositionsSimulation.current[node.id][sliderValue] || 0;
        });
        
        // Draw links
        svg.selectAll("line")
            .data(subsetGraphData.links)
            .enter().append("line")
            .attr("class", "link")
            .style("stroke", "#aaa")
            .attr("x1", d => {
                const sourceNode = subsetGraphData.nodes.find(node => node.id === d.source);
                return sourceNode.x;
            })
            .attr("y1", d => {
                const sourceNode = subsetGraphData.nodes.find(node => node.id === d.source);
                return sourceNode.y;
            })
            .attr("x2", d => {
                const targetNode = subsetGraphData.nodes.find(node => node.id === d.target);
                return targetNode.x;
            })
            .attr("y2", d => {
                const targetNode = subsetGraphData.nodes.find(node => node.id === d.target);
                return targetNode.y;
            });
        
        // Draw nodes
        svg.selectAll("circle")
            .data(subsetGraphData.nodes)
            .enter().append("circle")
            .attr("class", "node")
            .attr("r", d => {
                const boostHistoryLength = d.boost.filter(
                    boost => boost <= d3.scaleLinear()
                        .domain([0, maxSlider])
                        .range([d3.min(originalGraphData.nodes, n => n.sent), d3.max(originalGraphData.nodes, n => n.sent)])
                        (sliderValue)
                ).length;
                return 20 * Math.log((boostHistoryLength)/2 + Math.E);
            })
            .style("fill", d => d.id === node.id ? "red" : d.color)
            .attr("cx", d => d.x)
            .attr("cy", d => d.y)
            .on("click", (event, d) => handleNodeClick(d));
        
        // Draw node labels
        svg.selectAll(".node-label")
            .data(subsetGraphData.nodes)
            .enter().append("text")
            .attr("class", "node-label")
            .text(d => d.id)
            .attr("text-anchor", "middle")
            .attr("x", d => d.x)
            .attr("y", d => d.y)
	    .style("fill", "white");
        
        // Draw edge labels
        svg.selectAll(".edge-label")
            .data(subsetGraphData.links)
            .enter().append("text")
            .attr("class", "edge-label")
            .text(d => {
                const sliderScale = d3.scaleLinear()
                    .domain([0, maxSlider])
                    .range([d3.min(originalGraphData.nodes, n => n.sent), d3.max(originalGraphData.nodes, n => n.sent)]);
                const validRelations = d.id.filter(tuple => parseInt(tuple[1]) <= sliderScale(sliderValue));
                const sortedRelations = validRelations.sort((a, b) => parseInt(b[1]) - parseInt(a[1]));
                return sortedRelations.length > 0 ? sortedRelations[0][0] : "";
            })
            .attr("text-anchor", "middle")
            .attr("x", d => {
                const sourceNode = subsetGraphData.nodes.find(node => node.id === d.source);
                const targetNode = subsetGraphData.nodes.find(node => node.id === d.target);
                return (sourceNode.x + targetNode.x) / 2;
            })
            .attr("y", d => {
                const sourceNode = subsetGraphData.nodes.find(node => node.id === d.source);
                const targetNode = subsetGraphData.nodes.find(node => node.id === d.target);
                return (sourceNode.y + targetNode.y) / 2;
            })
	    .style("fill", "white");
        
        // Apply opacity based on the current slider
        opacityChanges(sliderValue);
        
    }, [originalGraphData, sliderValue, width]);

    // Highlight a specific node
    const highlightNode = useCallback((nodeData) => {
        if (!svgGRef.current) return;
        
        svgGRef.current.selectAll("circle")
            .style("fill", d => d.id === nodeData.id ? "red" : d.color);
        
        // Jump to the appropriate slider position
        jumpToSliderPosition(nodeData.sent);
    }, []);

    // Jump to a specific slider position
    const jumpToSliderPosition = useCallback((nodeSentValue) => {
        if (!originalGraphData) return;
        
        const xScale = d3.scaleLinear()
            .domain([
                d3.min(originalGraphData.nodes, d => d.sent), 
                d3.max(originalGraphData.nodes, d => d.sent)
            ])
            .range([0, maxSlider]);
        
        setSliderValue(Math.round(xScale(nodeSentValue)));
    }, [originalGraphData, maxSlider]);

    // Update node y positions based on slider
    const updateGraphYPosition = useCallback((sv) => {
        if (!originalGraphData || !svgGRef.current) return;
        
        const svg = svgGRef.current;
        
        // Update nodes' y position
        originalGraphData.nodes.forEach(node => {
            node.y = nodePositionsSimulation.current[node.id] ? 
                nodePositionsSimulation.current[node.id][sv] || 0 : 0;
        });
        
        // Update circle positions
        svg.selectAll("circle")
            .transition()
            .duration(50)
            .attr("cy", d => d.y);
        
        // Update node label positions
        svg.selectAll(".node-label")
            .transition()
            .duration(50)
            .attr("y", d => d.y);
        
        // Update link positions
        svg.selectAll("line")
            .transition()
            .duration(50)
            .attr("y1", d => {
                const sourceNode = originalGraphData.nodes.find(node => node.id === d.source);
                return sourceNode ? sourceNode.y : 0;
            })
            .attr("y2", d => {
                const targetNode = originalGraphData.nodes.find(node => node.id === d.target);
                return targetNode ? targetNode.y : 0;
            });
        
        // Update edge label positions
        svg.selectAll(".edge-label")
            .transition()
            .duration(50)
            .attr("y", d => {
                const sourceNode = originalGraphData.nodes.find(node => node.id === d.source);
                const targetNode = originalGraphData.nodes.find(node => node.id === d.target);
                return sourceNode && targetNode ? (sourceNode.y + targetNode.y) / 2 : 0;
            });
    }, [originalGraphData]);

    // Update element opacity based on slider
    const opacityChanges = useCallback((sv) => {
        if (!originalGraphData || !svgGRef.current) return;
        
        const svg = svgGRef.current;
        
        // Scale for comparing node.sent with slider value
        const xScale = d3.scaleLinear()
            .domain([
                d3.min(originalGraphData.nodes, d => d.sent), 
                d3.max(originalGraphData.nodes, d => d.sent)
            ])
            .range([0, maxSlider]);
        
        // Scale for y-based opacity
        const yScale = d3.scaleLinear()
            .domain([0, height])
            .range([1, 0]);
        
        // Update node opacity
        svg.selectAll("circle")
            .style("opacity", d => xScale(d.sent) <= sv ? yScale(d.y) : 0);
        
        // Update node label opacity
        svg.selectAll(".node-label")
            .style("opacity", d => xScale(d.sent) <= sv ? 1 : 0);
        
        // Update link opacity
        svg.selectAll("line")
            .style("opacity", d => {
                const sourceNode = originalGraphData.nodes.find(node => node.id === d.source);
                const targetNode = originalGraphData.nodes.find(node => node.id === d.target);
                return (sourceNode && targetNode && 
                        xScale(sourceNode.sent) <= sv && 
                        xScale(targetNode.sent) <= sv) ? 1 : 0;
            });
        
        // Update edge label opacity
        svg.selectAll(".edge-label")
            .style("opacity", d => {
                const sourceNode = originalGraphData.nodes.find(node => node.id === d.source);
                const targetNode = originalGraphData.nodes.find(node => node.id === d.target);
                return (sourceNode && targetNode && 
                        xScale(sourceNode.sent) <= sv && 
                        xScale(targetNode.sent) <= sv) ? 1 : 0;
            });
    }, [originalGraphData, height, maxSlider]);

    // Update edge labels based on slider
    const updateEdgeLabels = useCallback((sv) => {
        if (!originalGraphData || !svgGRef.current) return;
        
        const svg = svgGRef.current;
        
        svg.selectAll(".edge-label")
            .text(function(d) {
                const sliderScale = d3.scaleLinear()
                    .domain([0, maxSlider])
                    .range([
                        d3.min(originalGraphData.nodes, d => d.sent), 
                        d3.max(originalGraphData.nodes, d => d.sent)
                    ]);
                const validRelations = d.id.filter(tuple => parseInt(tuple[1]) <= sliderScale(sv));
                const sortedRelations = validRelations.sort((a, b) => parseInt(b[1]) - parseInt(a[1]));
                
                if (sortedRelations.length > 0) {
                    return sortedRelations[0][0];
                } else {
                    return this.textContent;
                }
            });
    }, [originalGraphData, maxSlider]);

    // Update node size based on boost history
    const updateNodeSize = useCallback((sv) => {
        if (!originalGraphData || !svgGRef.current) return;
        
        const svg = svgGRef.current;
        
        const sliderScale = d3.scaleLinear()
            .domain([0, maxSlider])
            .range([
                d3.min(originalGraphData.nodes, d => d.sent), 
                d3.max(originalGraphData.nodes, d => d.sent)
            ]);
        
        const scaledSliderValue = sliderScale(sv);
        
        svg.selectAll("circle")
            .attr("r", function(d) {
                if (!d.boost) return 20;
                const boostHistoryLength = d.boost.filter(boost => boost <= scaledSliderValue).length;
                const newRadius = 20 * Math.log((boostHistoryLength)/2 + Math.E);
                return newRadius;
            });
    }, [originalGraphData, maxSlider]);

    // Update the original text display
    const setOriginalText = useCallback((sv, highlightedNode) => {
        if (!originalGraphData) return;
        
        const originalTextElement = document.getElementById("original-text");
        if (!originalTextElement) return;
        
        // Create a scale to map slider value to sentence position
        const xScale = d3.scaleLinear()
            .domain([0, maxSlider])
            .range([
                d3.min(originalGraphData.nodes, d => d.sent), 
                d3.max(originalGraphData.nodes, d => d.sent)
            ]);
        
        // Get the current sentence position based on the slider
        const sentencePosition = xScale(sv);
        
        // Process text based on whether a node is highlighted
        let processedText = originalGraphData.text;
        
        if (highlightedNode) {
            // Find coreference cluster for the highlighted node
            let charOffsets = null;
            for (let i = 0; i < originalGraphData.coreferences.clusters_token_text.length; i++) {
                const cluster = originalGraphData.coreferences.clusters_token_text[i];
                const joinedText = cluster.join(' ').toLowerCase();
                
                if (joinedText.includes(highlightedNode.id.toLowerCase())) {
                    charOffsets = originalGraphData.coreferences.clusters_char_offsets[i];
                    break;
                }
            }
            
            if (charOffsets) {
                // Highlight all coreferences
                let modifiedText = originalGraphData.text;
                
                // Process offsets in reverse to avoid invalidating later offsets
                for (let i = charOffsets.length - 1; i >= 0; i--) {
                    const [start, end] = charOffsets[i];
                    modifiedText = modifiedText.slice(0, start) + 
                                  '<b>' + 
                                  modifiedText.slice(start, end+1) + 
                                  '</b>' + 
                                  modifiedText.slice(end+1);
                }
                
                processedText = modifiedText;
            } else {
                // If not in a coreference cluster, highlight direct matches
                const nodeSentIndex = highlightedNode.sent - 1;
                const sentences = originalGraphData.text.split('\n');
                if (sentences[nodeSentIndex]) {
                    const sentenceToBold = sentences[nodeSentIndex];
                    const wordsToBold = highlightedNode.id.split(' ');
                    const regExp = new RegExp(`\\b(${wordsToBold.join('|')})\\b`, "gi");
                    const boldedSentence = sentenceToBold.replace(regExp, match => `<b>${match}</b>`);
                    sentences[nodeSentIndex] = boldedSentence;
                    processedText = sentences.join('\n');
                }
            }
        }
        
        // Highlight the first X sentences based on slider position
        const lines = processedText.split("\n");
        const highlightedLines = lines.map((line, index) => {
            if (index < sentencePosition) {
                return `<span style="background-color: gray;">${line}</span>`;
            }
            return line;
        });
        
        originalTextElement.innerHTML = highlightedLines.join("<br>");
    }, [originalGraphData, maxSlider]);

    // Handle play/pause button
    const handlePlayPause = useCallback(() => {
        if (isPlaying) {
            clearInterval(intervalRef.current);
        } else {
            intervalRef.current = setInterval(() => {
                setSliderValue(prev => {
                    if (prev < maxSlider) {
                        return prev + 1;
                    } else {
                        clearInterval(intervalRef.current);
                        setIsPlaying(false);
                        return prev;
                    }
                });
            }, 50 / speeds[speedIndex]);
        }
        setIsPlaying(!isPlaying);
    }, [isPlaying, speedIndex, maxSlider]);

    // Handle speed change
    const handleSpeedChange = useCallback(() => {
        const newSpeedIndex = (speedIndex + 1) % speeds.length;
        setSpeedIndex(newSpeedIndex);
        
        if (isPlaying) {
            clearInterval(intervalRef.current);
            intervalRef.current = setInterval(() => {
                setSliderValue(prev => {
                    if (prev < maxSlider) {
                        return prev + 1;
                    } else {
                        clearInterval(intervalRef.current);
                        setIsPlaying(false);
                        return prev;
                    }
                });
            }, 50 / speeds[newSpeedIndex]);
        }
    }, [speedIndex, isPlaying, maxSlider]);

    // Render entity list buttons
    const renderEntityList = useCallback(() => {
        if (!originalGraphData) return null;
        
        return originalGraphData.nodes.map(node => (
            <button
                key={node.id}
                onClick={() => handleNodeClick(node)}
                style={{
                    margin: '5px',
                    backgroundColor: highlightedNode?.id === node.id ? 'red' : undefined,
                    color: highlightedNode?.id === node.id ? 'white' : undefined
                }}
            >
                {node.id}
            </button>
        ));
    }, [originalGraphData, highlightedNode, handleNodeClick]);

    return (
        <div id="container" style={{ display: 'flex', width: '100%', height: '100%' }}>
            <div id="left-pane" style={{ 
                width: '50%', 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'space-between', 
                border: '1px solid #ddd'
            }}>
                <div id="my_dataviz" style={{ 
                    width: '100%', 
                    height: '70%', 
                    border: '1px solid #ddd' 
                }}>
                    <svg ref={svgRef}></svg>
                </div>
                
                <div id="slider-container" style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    width: '100%', 
                    margin: '0px auto', 
                    textAlign: 'center', 
                    border: '1px solid #ddd' 
                }}>
                    <div id="slider-buttons">
                        <input 
                            type="button" 
                            id="slider-button-start-stop" 
                            value={isPlaying ? "Pause" : "Start"} 
                            onClick={handlePlayPause}
                        />
                        <input 
                            type="button" 
                            id="slider-button-speed" 
                            value={speeds[speedIndex]} 
                            onClick={handleSpeedChange}
                        />
                    </div>
                    <input 
                        type="range" 
                        id="value-slider" 
                        min="0" 
                        max={maxSlider} 
                        value={sliderValue} 
                        step="1" 
                        style={{ width: '100%' }}
                        onChange={(e) => setSliderValue(parseInt(e.target.value))}
                    />
                    <div id="slider-value">
                        Progress: {Math.floor(sliderValue/maxSlider*100)}%
                    </div>
                </div>
                
                <div id="bottom-left" style={{ 
                    height: '20%', 
                    overflowY: 'auto', 
                    border: '1px solid #ddd' 
                }}>
                    <h2>Entity Names</h2>
                    <div id="entity-list">
                        {renderEntityList()}
                    </div>
                </div>
            </div>
            
            <div id="right-pane" style={{ 
                width: '50%', 
                height: '100%', 
                border: '1px solid #ddd' 
            }}>
                <h2>Original Text</h2>
                <div id="original-text"></div>
            </div>
        </div>
    );
};

export default Project3;
